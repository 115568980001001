"use client";

import { Box, Button, Flex, Grid, Heading, Stack, Text, VStack } from "@biblioteksentralen/react";
import { ArrowLeft, RefreshCw } from "react-feather";
import { DefaultContainer } from "../../../../components/ContentContainer";
import { NotFoundFigure } from "../../../../components/errorPages/NotFoundFigure";
import { LocalizedNextLink } from "../../../../components/LocalizedNextLink";
import { useSanityAuth } from "../../../../components/sanityAuth/useSanityAuth";
import { useTranslation } from "../../../../utils/hooks/useTranslation";

export const NotFoundClientSide = () => {
  const sanityAuth = useSanityAuth();
  const { t } = useTranslation();

  return (
    <DefaultContainer padding="4rem 0 8rem">
      <Grid gridTemplateColumns={{ base: "1fr", sm: "1fr 1fr" }}>
        <Flex
          justifyContent={{ base: "center", md: "flex-end" }}
          alignItems="center"
          marginRight={{ base: undefined, md: "6rem" }}
          marginBottom={{ base: "2rem", md: undefined }}
        >
          <Box width={{ base: "10rem", md: "14rem" }} height={{ base: "10rem", md: "14rem" }}>
            <NotFoundFigure />
          </Box>
        </Flex>
        <VStack
          gap="1rem"
          alignItems="flex-start"
          maxWidth={{ base: "25rem", sm: "19rem" }}
          padding={{ base: "0 3rem", sm: "0 2rem 0 0" }}
        >
          <Heading as="h1" size="3xl" marginTop="0 !important">
            {t("Oisann!")}
          </Heading>
          <Heading as="h2" fontSize="2xl" marginTop="0 !important">
            {t("Her mangler det noe.")}
          </Heading>
          <Text marginTop="0 !important" fontSize="sm">
            {sanityAuth.isAuthenticated
              ? t("Hvis du nylig har publisert innhold, kan det ta et par minutter før siden dukker opp.")
              : t("Denne siden finnes ikke, eller den er så fersk at innholdet ikke er på plass enda.")}
          </Text>
          <Text marginTop="0 !important" fontSize="sm">
            {"("}
            {t("Feilkode 404")}
            {")"}
          </Text>
          <Stack gap="0.25rem" marginTop="0 !important" flexDirection={{ base: "column", md: "row" }}>
            {sanityAuth.isAuthenticated && (
              <Button
                onClick={() => window.location.reload()}
                size="md"
                leftIcon={<RefreshCw size="1em" />}
                marginTop={{ base: "0.5rem !important", md: "1rem !important" }}
              >
                {t("Prøv på nytt")}
              </Button>
            )}
            <Button
              as={LocalizedNextLink}
              href="/"
              size="md"
              leftIcon={<ArrowLeft size="1em" />}
              marginTop={{
                base: sanityAuth.isAuthenticated ? "1rem !important" : "0.5rem !important",
                md: "1rem !important",
              }}
              variant={sanityAuth.isAuthenticated ? "secondary" : undefined}
            >
              {t("Gå til forsiden")}
            </Button>
          </Stack>
        </VStack>
      </Grid>
    </DefaultContainer>
  );
};
